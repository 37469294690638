import React from "react"
import CartProvider from "./src/context/cartcontext"
import { AuthProvider } from "./src/context/auth"
import "./i18n"

export const onInitialClientRender = () => {
  if (typeof window === "undefined") return // Užtikriname, kad kodas neveikia SSR metu

  // Nustatome vartotojo kalbą pagal naršyklę
  const userLang = navigator.language || navigator.languages[0] || "lt"

  // Pridedame Google Translate skriptą
  const script = document.createElement("script")
  script.src =
    "https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
  script.async = true
  document.body.appendChild(script)

  window.googleTranslateElementInit = () => {
    const checkGoogleTranslate = setInterval(() => {
      if (window.google && window.google.translate) {
        console.log("✅ Google Translate sėkmingai įkeltas!")

        if (!document.getElementById("google_translate_element")) {
          console.error("❌ Elementas 'google_translate_element' nerastas.")
          return
        }

        new window.google.translate.TranslateElement(
          {
            pageLanguage: "lt", // Originali puslapio kalba
            includedLanguages: "en,de,fr,es,ru,it,pl", // Galimos vertimo kalbos
            layout:
              window.google.translate.TranslateElement.InlineLayout.SIMPLE,
          },
          "google_translate_element"
        )

        clearInterval(checkGoogleTranslate) // Sustabdome tikrinimą

        // Automatinis vertimas
        setTimeout(() => {
          changeGoogleTranslateLanguage(userLang)
        }, 1000)
      }
    }, 500) // Tikriname kas 500ms
  }

  script.onload = () => {
    console.log("✅ Google Translate skriptas sėkmingai užkrautas.")
    window.googleTranslateElementInit()
  }

  script.onerror = () => {
    console.error(
      "❌ Nepavyko įkelti Google Translate skripto. Patikrinkite tinklo ryšį arba Netlify blokavimą."
    )
  }
}

// Funkcija keisti kalbą
const changeGoogleTranslateLanguage = lang => {
  const googleFrame = document.querySelector("iframe.goog-te-banner-frame")
  if (!googleFrame) {
    console.warn("⚠ Google Translate iframe nerastas.")
    return
  }

  const langMap = {
    de: "de", // Vokiečių
    en: "en", // Anglų
    fr: "fr", // Prancūzų
    es: "es", // Ispanų
    ru: "ru", // Rusų
    it: "it", // Italų
    pl: "pl", // Lenkų
    lt: "lt", // Lietuvių (numatytoji)
  }

  const targetLang = langMap[lang.split("-")[0]] || "lt"

  if (window.google && window.google.translate) {
    document.cookie = `googtrans=/lt/${targetLang}; path=/`
    window.location.reload()
  }
}

export const wrapRootElement = ({ element }) => {
  return (
    <AuthProvider>
      <CartProvider>{element}</CartProvider>
    </AuthProvider>
  )
}

export const onRouteUpdate = ({ location }) => {
  const path = location.pathname

  // Patikrinkite, ar URL prasideda nuo /en, /lt ar /ru
  if (
    path.startsWith("/en") ||
    path.startsWith("/lt") ||
    path.startsWith("/ru")
  ) {
    // Nukreipkite į pagrindinį puslapį
    window.location.replace("/")
  }
}
